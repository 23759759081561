// 新闻详情
<template>
  <div class="content">
    <div class="box">
      <div class="left">
        <div class="top">
          <div style="margin-top: 100px; display: flex">
            <p
              style="font-size: 14px; line-height: 21px"
              @click="go()"
              class="goback"
            >
              返回 |
            </p>
            <el-breadcrumb
              separator-class="el-icon-arrow-right"
              style="margin-left: 10px; line-height: 41px"
            >
              <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
              <el-breadcrumb-item :to="{ path: '/news' }"
                >新闻资讯</el-breadcrumb-item
              >
              <el-breadcrumb-item>
                <span v-if="Consultation.type == 1">
                  <a href="#/marketNews">市场趋势</a>
                </span>
                <span v-if="Consultation.type == 2">
                  <a href="#/professionNews">行业动态</a>
                </span>
                <span v-if="Consultation.type == 3">
                  <a href="#/companyNews">企业资讯</a>
                </span>
                <span v-if="Consultation.type == 4">
                  <a href="#/productNews">产品信息</a>
                </span>
              </el-breadcrumb-item>

              <span style="color: #004aa1; margin-right: 20px"
                >{{ Consultation.cnTit.slice(0, 7) }} ...</span
              >
            </el-breadcrumb>
          </div>
        </div>
        <div class="keyword">
          文章关键字
          <div class="p"></div>
          <div class="text">
            <p
              v-for="item in Consultation.keywords"
              :key="index"
              class="textcontent"
              @click="gotohot(item)"
            >
              <span> {{ item }}</span>
            </p>
          </div>
          <div class="p"></div>
          <div class="hot">热门文章</div>
          <div class="p"></div>
          <div class="hotArticle">
            <div
              v-for="(item, index) in hotContent"
              :key="index"
              style="display: flex; margin-top: 5px"
            >
              <span v-if="index == 0" style="margin-top: 8px; color: #fb2c2c"
                >1.</span
              >
              <span v-if="index == 1" style="margin-top: 8px; color: #b86161"
                >2.</span
              >
              <span v-if="index == 2" style="margin-top: 8px; color: #f38143"
                >3.</span
              >
              <span v-if="index == 3" style="margin-top: 8px; color: #62a0fb"
                >4.</span
              >
              <span v-if="index == 4" style="margin-top: 8px; color: #34f805"
                >5.</span
              >
              <a :href="`#/NewsDetail/${item.cnId}`">
                <p>
                  {{ item.cnTit }}
                </p>
              </a>
            </div>
            <div class="p"></div>
          </div>
          <div class="product">相关产品</div>
          <div class="p"></div>
          <div class="list" v-if="Consultation.guanlian == 1">
            <div
              class="item"
              v-for="(item, index) in HardwareList"
              :key="index"
            >
              <a :href="`#/HardwareDetail/${item.devId}`">
                <div class="devImg">
                  <img :src="item.devImg" alt="" />
                </div>
                <div class="text">
                  <h3>{{ item.devName }}</h3>
                  <!-- <h3>{{ item.devModel }}</h3> -->
                  <button>详情参数</button>
                </div>
              </a>
            </div>
          </div>
          <div class="list1" v-if="Consultation.guanlian == 2">
            <div
              class="item"
              v-for="(item, index) in HardwareList"
              :key="index"
            >
              <a :href="`#/SoftwareDetail/${item.proId}/1`">
                <div class="devImg">
                  <img class="img" :src="item.proImg" alt="" />
                </div>
                <div class="text">
                  <h3>{{ item.proName }}</h3>
                  <!-- <p>{{ item.title }}</p> -->
                  <button>详情参数</button>
                </div>
              </a>
            </div>
          </div>
          <div class="list1" v-if="Consultation.guanlian == 3">
            <div
              class="item"
              v-for="(item, index) in HardwareList"
              :key="index"
            >
              <a :href="`#/SoftwareDetail/${item.proId}/1`">
                <div class="devImg">
                  <img class="img" :src="item.proImg" alt="" />
                </div>
                <div class="text">
                  <h3>{{ item.proName }}</h3>
                  <!-- <p>{{ item.title }}</p> -->
                  <button>详情参数</button>
                </div>
              </a>
            </div>
          </div>
          <div class="list1" v-if="Consultation.guanlian == 4">
            <div
              class="item"
              v-for="(item, index) in HardwareList"
              :key="index"
            >
              <a :href="`#/SoftwareDetail/${item.proId}/1`">
                <div class="devImg">
                  <img class="img" :src="item.proImg" alt="" />
                </div>
                <div class="text">
                  <h3>{{ item.proName }}</h3>
                  <!-- <p>{{ item.title }}</p> -->
                  <button>详情参数</button>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="infor">
        <div class="title">
          <!-- 标题 -->
          <p>{{ Consultation.cnTit }}</p>
          <!-- 时间 -->
          <p>
            {{ Consultation.createTime.slice(0, 10) }}
          </p>
        </div>
        <div class="details">
          <!-- 内容 -->
          <div class="details_div" v-html="Consultation.cnContent"></div>
          <div class="share">
            <span>分享到：</span>
            <img
              @click="shareToQQ"
              src="../assets/news/QQ.png"
              alt="qq分享图片"
            />
            <img
              @click="shareToMicroblog()"
              src="../assets/news/weibo.png"
              alt="微博分享图片"
            />
            <img
              @click="shareToRoom()"
              src="../assets/news/QQkj.png"
              alt="qq空间分享图片"
            />
          </div>
        </div>
        <div class="Article">
          <p
            style="
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            "
          >
            上一篇 :
            <span @click="gotoPrev()" class="content">
              <span style=""> {{ nextTit }}</span>
              <span v-if="show">无</span>
            </span>
            <span v-html="htmlP"></span>
          </p>
          <p
            style="
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              text-align: right;
            "
          >
            下一篇 :
            <span @click="gotoNext()" class="content">
              {{ prevTit }}
            </span>
            <span v-html="htmlN"></span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
const share = {
  title: "分享测试",
  desc: "测试描述",
  image_url: [
    "https://pic3.zhimg.com/80/v2-ab0718575cc9337b24bfc7578f5e6d32_720w.jpg",
  ],
  share_url: "http://chirui.xuruidea.com",
};
import { mapMutations } from "vuex";
export default {
  name: "async",
  metaInfo() {
    return {
      title: this.$store.state.newData.seoTitle ||'旭瑞智能-智慧水利智慧城管智慧农业解决方案提供商',
      meta: [
        {
          name: "keywords", // 设置关键字
          content: this.$store.state.newData.seoKeywords,
        },
        {
          name: "description", // 设置页面描述
          content: this.$store.state.newData.seoDesc,
        },
      ],
    };
  },
  computed: {},
  filterHtmlTag(t) {
    if (!t) return "";
    return t.toString().replace(/<.*?>/g, "");
  },
  data() {
    return {
      hot: [],
      content: [],
      hotContent: [],
      HardwareList: [],
      pageName: this.$store.state.newData.seoTitle,
      detailId: "",
      ArticlePId: "", //上一页
      ArticleNId: "", //下一页
      total: "",
      ArticlePContent: "",
      ArticleNContent: "",
      Consultation: "",
      lang: "",
      htmlP: "",
      htmlN: "",
      Informationlist: [],
      Nextnews: "", // 下一条
      Lastnews: "", // 上一条
      length: "",
      page: 1,
      size: 99,
      cnIdlist: [],
      show: false,
      prevTit: "",
      nextTit: "",
    };
  },
  mounted() {
    setTimeout(() => {
      this.pageName = "async";
    }, 2000);
  },
  created() {
    this.$API.getArticleListAll().then((res) => {
      this.content = res.data;
      res.data.map((item) => {
        this.hot.push(item.cnViews);
      });
      this.hot.sort((a, b) => {
        return b - a;
      });
      res.data.map((item) => {
        if (this.hot[0] == item.cnViews) {
          this.hotContent[0] = item;
        }
        if (this.hot[1] == item.cnViews) {
          this.hotContent[1] = item;
        }
        if (this.hot[2] == item.cnViews) {
          this.hotContent[2] = item;
        }
        if (this.hot[3] == item.cnViews) {
          this.hotContent[3] = item;
        }
        if (this.hot[4] == item.cnViews) {
          this.hotContent[4] = item;
        }
      });
      console.log(this.hotContent);
    });
    this.lang = localStorage.getItem("newv");
    this.detailId = this.$route.params.id;
    this.getqueryById(this.detailId);
    this.getArticleP(this.ArticlePId);
    this.getArticleN(this.ArticleNId);
    this.getNews();
    this.Option();
    this.getNewss();
    console.log(this.cnIdlist);
    this.prevNum();
    this.nextNum();
  },
  watch: {
    $route(to, from) {
      console.log(to);
      console.log(from);
      if (to.name == from.name) {
        this.$router.go(0);
      }
    },
  },
  methods: {
    ...mapMutations(["setNewData", "setHot"]),
    go() {
      this.$router.go(-1);
    },
    gotohot(val) {
      let data = {
        keywords: val,
        pageNum: 1,
        pageSize: 0,
      };
      this.$API.getFenNewsList(data).then((res) => {
        console.log("res :新闻>> ", res);
        this.setHot(res.data);
      });
      this.$router.push("/hotarticle");
    },
    getNewss() {
      let data = {
        pageNum: this.page,
        pageSize: this.size,
      };
      this.$API.getFenNewsList(data).then((res) => {
        console.log("res :新闻>> ", res);
        if (this.detailId == res.data.list[0].cnId) {
          this.show = true;
        }
        res.data.list.map((item, index) => {
          this.cnIdlist.push(item.cnId);
        });
      });
    },
    shareToWX() {
      window.open(
        "https://service.weibo.com/share/share.php?url=" +
          encodeURIComponent(share.share_url) +
          "&title=" +
          share.title +
          "&pic=" +
          share.image_url +
          "&searchPic=true"
      );
    },
    shareToQQ() {
      //此处分享链接内无法携带图片
      window.open(
        "https://connect.qq.com/widget/shareqq/index.html?url=" +
          encodeURIComponent(share.share_url) +
          "&title=" +
          share.title +
          "&desc=" +
          share.desc
      );
    },
    shareToRoom() {
      let image_urls = share.image_url.map(function (image) {
        return encodeURIComponent(image);
      });
      //跳转地址
      window.open(
        "https://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?url=" +
          encodeURIComponent(share.share_url) +
          "&title=" +
          share.title +
          "&pics=" +
          image_urls.join("|") +
          "&summary=" +
          share.desc
      );
    },
    shareToMicroblog() {
      //跳转地址
      window.open(
        "https://service.weibo.com/share/share.php?url=" +
          encodeURIComponent(share.share_url) +
          "&title=" +
          share.title +
          "&pic=" +
          share.image_url +
          "&searchPic=true"
      );
    },
    getqueryById(val) {
      console.log("获取参数的id", val);
      let data = {
        cnId: val,
      };
      this.$API.getNewsDetails(data).then((res) => {
        console.log("res :详情>> ", res);
        console.log(res.data.guanlian, "关联产品");
        console.log(res.data.seoTitle);
        // this.pageName = res.data.seoTitle;
        if (res.data.guanlian == 1) {
          let data = {};
          this.$API.getHardware(data).then((res) => {
            console.log("res :硬件产品列表>> ", res);
            this.HardwareList = res.data;

            // 随机获取数组不重复数据
            function getArrayItems(arr, num) {
              var temp_array = new Array();
              for (var index in arr) {
                temp_array.push(arr[index]);
              }
              var return_array = new Array();
              for (var i = 0; i < num; i++) {
                if (temp_array.length > 0) {
                  var arrIndex = Math.floor(Math.random() * temp_array.length);
                  return_array[i] = temp_array[arrIndex];
                  temp_array.splice(arrIndex, 1);
                } else {
                  break;
                }
              }
              return return_array;
            }
            var ArrList = this.HardwareList;
            this.HardwareList = getArrayItems(ArrList, 4);
            console.log(this.HardwareList);
          });
        }
        if (res.data.guanlian == 2) {
          let data = { proType: 1 };
          this.$API.getSoftware(data).then((res) => {
            console.log("res :硬件产品列表>> ", res);
            this.HardwareList = res.data;
            console.log(this.HardwareList);
            // 随机获取数组不重复数据
            function getArrayItems(arr, num) {
              var temp_array = new Array();
              for (var index in arr) {
                temp_array.push(arr[index]);
              }
              var return_array = new Array();
              for (var i = 0; i < num; i++) {
                if (temp_array.length > 0) {
                  var arrIndex = Math.floor(Math.random() * temp_array.length);
                  return_array[i] = temp_array[arrIndex];
                  temp_array.splice(arrIndex, 1);
                } else {
                  break;
                }
              }
              return return_array;
            }
            var ArrList = this.HardwareList;
            this.HardwareList = getArrayItems(ArrList, 4);
            console.log(this.HardwareList);
          });
        }
        if (res.data.guanlian == 3) {
          let data = { proType: 2 };
          this.$API.getSoftware(data).then((res) => {
            console.log("res :硬件产品列表>> ", res);
            this.HardwareList = res.data;

            // 随机获取数组不重复数据
            function getArrayItems(arr, num) {
              var temp_array = new Array();
              for (var index in arr) {
                temp_array.push(arr[index]);
              }
              var return_array = new Array();
              for (var i = 0; i < num; i++) {
                if (temp_array.length > 0) {
                  var arrIndex = Math.floor(Math.random() * temp_array.length);
                  return_array[i] = temp_array[arrIndex];
                  temp_array.splice(arrIndex, 1);
                } else {
                  break;
                }
              }
              return return_array;
            }
            var ArrList = this.HardwareList;
            this.HardwareList = getArrayItems(ArrList, 4);
            console.log(this.HardwareList);
          });
        }
        if (res.data.guanlian == 4) {
          let data = { proType: 3 };
          this.$API.getSoftware(data).then((res) => {
            console.log("res :硬件产品列表>> ", res);
            this.HardwareList = res.data;

            // 随机获取数组不重复数据
            function getArrayItems(arr, num) {
              var temp_array = new Array();
              for (var index in arr) {
                temp_array.push(arr[index]);
              }
              var return_array = new Array();
              for (var i = 0; i < num; i++) {
                if (temp_array.length > 0) {
                  var arrIndex = Math.floor(Math.random() * temp_array.length);
                  return_array[i] = temp_array[arrIndex];
                  temp_array.splice(arrIndex, 1);
                } else {
                  break;
                }
              }
              return return_array;
            }
            var ArrList = this.HardwareList;
            this.HardwareList = getArrayItems(ArrList, 4);
            console.log(this.HardwareList);
          });
        }

        this.Consultation = res.data;
        this.setNewData(this.Consultation);
        console.log(this.Consultation);
        console.log("上一页文章id", this.ArticlePId);
        console.log("下一页文章id", this.ArticleNId);
      });
    },
    gotoNext() {
      let data = {
        pageNum: this.page,
        pageSize: this.size,
      };
      this.$API.getFenNewsList(data).then((res) => {
        console.log("res :新闻>> ", res);
        res.data.list.map((item, index) => {
          if (this.detailId == item.cnId) {
            index = index * 1 + 1;
            this.ArticlePId = res.data.list[index].cnId;
            console.log(this.ArticlePId);
            let id = this.ArticlePId;
            this.getArticleN(id);
            this.$router.push({ path: `/NewsDetail/${id}` });
          }
        });
      });
    },
    gotoPrev() {
      let data = {
        pageNum: this.page,
        pageSize: this.size,
      };
      this.$API.getFenNewsList(data).then((res) => {
        console.log("res :新闻>> ", res);
        res.data.list.map((item, index) => {
          if (this.detailId == item.cnId) {
            index = index * 1 - 1;
            this.ArticleNId = res.data.list[index].cnId;
            let id = this.ArticleNId;
            this.$router.push({ path: `/NewsDetail/${id}` });
          }
        });
      });
    },
    prevNum() {
      let data = {
        pageNum: this.page,
        pageSize: this.size,
      };
      this.$API.getFenNewsList(data).then((res) => {
        console.log("res :新闻>> ", res);
        res.data.list.map((item, index) => {
          if (this.detailId == item.cnId) {
            index = index * 1 + 1;
            this.ArticleNId = res.data.list[index].cnId;
            let id = this.ArticleNId;
            let data = {
              cnId: id,
            };
            this.$API.getNewsDetails(data).then((res) => {
              console.log("res 下一页详情", res);
              console.log(res.data.cnTit);
              this.prevTit = res.data.cnTit;
            });
          }
        });
      });
    },
    nextNum() {
      let data = {
        pageNum: this.page,
        pageSize: this.size,
      };
      this.$API.getFenNewsList(data).then((res) => {
        console.log("res :新闻>> ", res);
        res.data.list.map((item, index) => {
          if (this.detailId == item.cnId) {
            index = index * 1 - 1;
            this.ArticlePId = res.data.list[index].cnId;
            let id = this.ArticlePId;
            let data = {
              cnId: id,
            };
            this.$API.getNewsDetails(data).then((res) => {
              console.log("res 上一页详情", res);
              console.log(res.data.cnTit);
              this.nextTit = res.data.cnTit;
            });
          }
        });
      });
    },
    getArticleP(val) {
      let data = {
        cnId: val,
      };
      if (this.ArticlePId > 0) {
        this.$API.getNewsDetails(data).then((res) => {
          console.log("res 上一页详情", res);
          if (res.data == null) {
            this.ArticlePContent = "";
          } else {
            this.ArticlePContent = res.data;
          }
        });
      }
    },
    getArticleN(val) {
      let data = {
        cnId: val,
      };
      if (this.ArticleNId > 0) {
        this.$API.getNewsDetails(data).then((res) => {
          console.log("res 下一页详情", res);
          this.ArticleNContent = res.data;
        });
      }
    },
    getNews() {
      let data = {
        pageNum: 1,
        pageSize: 5,
      };
      this.$API.getFenNewsList(data).then((res) => {
        console.log("总条数", res.data.total);
        this.total = res.data.total;
        console.log(this.detailId, "总Id数");
      });
    },
    Option() {
      let data = {};
      this.$API.getArticleListAll(data).then((res) => {
        this.total = res.data.length;
        this.detailId == 1 ? ((this.htmlP = " "), (this.htmlN = "无")) : "";
      });
    },
  },
  mounted() {
    if (this.detailId == this.total && this.ArticlePId > this.total) {
      (this.htmlP = "无"), (this.htmlN = " ");
    }
  },
};
</script>
<style lang="less" scoped>
/deep/.el-breadcrumb__inner {
  color: #606266;
  font-weight: normal;
  span {
    a {
      font-weight: normal;
      color: #606266;
    }
    a:hover {
      color: #409eff;
      cursor: pointer;
    }
  }
}
.goback:hover {
  color: #409eff;
  cursor: pointer;
}
.content {
  background-color: #f1f1f1;
  .box {
    display: flex;
    justify-content: center;
    width: 80%;
    margin: auto;
    // background-color: #f1f1f1;
  }
  .left {
    width: 30%;
    .top {
      background-color: #fff;
      .goback {
        padding: 10px 20px;
      }
      .span {
        width: 100%;
        overflow: hidden; /*内容超出后隐藏*/
        text-overflow: ellipsis; /* 超出内容显示为省略号 */
        white-space: nowrap; /* 文本不进行换行 */
      }
    }
    .keyword {
      margin-top: 50px;
      width: 100%;
      .list {
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 10px 0;
        flex-wrap: wrap;
        .item {
          width: 48%;
          margin: 1% 1%;
          height: 320px;
          background-color: #004aa1;
          border-radius: 10px;
          box-shadow: 0 0 10px 2px rgb(210, 210, 210);
          .devImg {
            width: 100%;
            height: 200px;
            // border-radius: 8px;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            background-color: #fff;
            img {
              width: 100%;
              height: 100%;
              border-radius: 8px;
            }
          }
          .text {
            width: 100%;
            height: 100px;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
            color: #fff;
            text-align: center;

            p {
              font-size: 18px;
              font-weight: 600;
            }
            span {
              // cursor: pointer;
              display: block;
              padding: 5px 40px;
              // border: 1px solid #fff;
              border-radius: 20px;
              color: #fff;
            }
            button {
              cursor: pointer;
              display: block;
              padding: 5px 40px;
              border: 1px solid #fff;
              border-radius: 20px;
              color: white;
              background-color: #004aa1;
            }
            button:hover {
              color: black;
              background-color: #fff;
            }
          }
        }
      }
      .list1 {
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 10px 0;
        flex-wrap: wrap;
        .item {
          width: 48%;
          margin: 1% 1%;
          height: 240px;
          background-color: #004aa1;
          border-radius: 10px;
          box-shadow: 0 0 10px 2px rgb(210, 210, 210);
          .devImg {
            width: 100%;
            height: 120px;
            // border-radius: 8px;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            background-color: #fff;
            img {
              width: 100%;
              height: 120px;
              border-radius: 8px;
            }
          }
          .text {
            width: 100%;
            height: 100px;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
            color: #fff;
            text-align: center;
            p {
              font-size: 18px;
              font-weight: 600;
            }
            span {
              // cursor: pointer;
              display: block;
              padding: 5px 40px;
              // border: 1px solid #fff;
              border-radius: 20px;
              color: #fff;
            }
            button {
              cursor: pointer;
              display: block;
              padding: 5px 40px;
              border: 1px solid #fff;
              border-radius: 20px;
              color: white;
              background-color: #004aa1;
            }
            button:hover {
              color: black;
              background-color: #fff;
            }
          }
        }
      }
      .p {
        width: 100%;
        height: 4px;
        background-color: #0079fe;
        border-radius: 10px;
        margin-top: 10px;
      }
      .text {
        display: flex;
        // justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 5px;
        .textcontent:hover {
          cursor: pointer;
        }
        p {
          background-color: #0149a1;
          color: #fff;
          padding: 5px 15px;
          border-radius: 50px;
          margin-right: 5px;
          margin-top: 5px;
          //  width:15%;
          text-align: center;
        }
      }
      .hot {
        margin: 20px 0 15px 0;
      }
      .hotArticle {
        a {
          color: #606266;
          margin-top: 5px;
          width: 100%;
        }
        p {
          // width: 460px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        a:hover {
          color: #0079fe;
        }
      }
      .product {
        margin: 20px 0;
      }
    }
  }
  .infor {
    width: 70%;
    margin-top: 10%;
    // margin: 50px auto;
    // background-color: #f1f1f1;
    padding: 50px 30px;
    .title {
      height: 100px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      border-bottom: 1px solid #999;
      p:nth-child(1) {
        font-size: 24px;
        font-weight: 600;
        color: #004aa1;
      }
    }
  }
  .details {
    padding: 30px 0;
    border-bottom: 2px solid #999;
    position: relative;
    .details_div {
      text-align: left;
      /deep/ p:nth-child(1) {
        text-align: left !important;
        img {
          width: 550px;
          // height: 368px;
          border-radius: 5px;
        }
      }
      /deep/ p:nth-child(2) {
        margin-top: 20px;
        margin-bottom: 20px;
        text-align: center;
        margin: auto;
        margin: 40px 0 40px 0;
        img {
          width: 550px;
          // height: 368px;
          border-radius: 5px;
          margin: 0 auto;
        }
      }
      /deep/ p:nth-child(3) {
        margin: 40px 0;
        img {
          width: 550px;
          margin-bottom: 20px;
        }
      }
      /deep/ p:nth-child(4) {
        text-align: center;
        margin-bottom: 50px;

        img {
          // max-width: 550px;
          // height: 368px;
          // border-radius: 5px;
          // max-width: 550px;
        }
        // text-align: center;
      }
      /deep/ p:nth-child(5) {
        margin-top: 20px;
        img {
          width: 550px;
          // height: 368px;
        }
        margin-bottom: 40px;
      }
      /deep/ p:nth-child(6) {
        text-align: center;
        img {
          width: 550px;
          // height: 368px;
        }
        margin-bottom: 20px;
        margin-top: 20px;
      }
      /deep/ p:nth-child(7) {
        margin-bottom: 40px;
        margin-top: 40px;
      }
      /deep/ p:nth-child(8) {
        margin-bottom: 40px;
        margin-top: 40px;
      }
    }
    // div {
    //   text-align: center;
    //   .details_img {
    //     width: 93%;
    //     // width: 480px;
    //     // height: 350px;
    //     text-align: center;
    //     margin-top: 60px;
    //     margin-bottom: 60px;
    //     // margin: auto;
    //   }
    //   p:nth-child(1){
    //     margin-bottom: 20px;
    //   }
    // }
    .share {
      margin-top: 0px;
      position: absolute;
      right: 10px;
      bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 180px;
      img {
        width: 24px;
        height: 24px;
        cursor: pointer;
      }
    }
  }
  .Article {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    p {
      width: 45%;
      color: #004aa1;
      .content {
        cursor: pointer;
      }
    }
  }
}
</style>
